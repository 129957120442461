
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/index.vue?macro=true";
import { default as indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta } from "/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta?.name ?? "index",
    path: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta?.path ?? "/",
    props: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta?.props ?? false,
    meta: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta || {},
    alias: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta?.alias || [],
    redirect: indexL_F5_nftc6auqujDDqlb32WrEv0uWF3vxRHeeUvvZ2AMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/index.vue")
  },
  {
    name: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta?.name ?? "lang",
    path: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta?.path ?? "/:lang?",
    props: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta?.props ?? false,
    meta: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta || {},
    alias: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta?.alias || [],
    redirect: indexFWrJsMzoGj8mknhjKk3xX6E1bICDU2seuCCF5j2akQ4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta?.name ?? "lang-voucher-start",
    path: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta?.path ?? "/:lang?/voucher/start",
    props: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta?.props ?? false,
    meta: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta || {},
    alias: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta?.alias || [],
    redirect: start0NkS3lU9sL2tzdNE6iZYBf_u_45nOZ6F3qATePJJDHWNwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta?.name ?? "lang-purchase-start",
    path: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta?.path ?? "/:lang?/purchase/start",
    props: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta?.props ?? false,
    meta: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta || {},
    alias: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta?.alias || [],
    redirect: startV8dcBOpFVPHwheu8EY4wBF6tgFRM8VyqdtcpnpXUXzMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta?.name ?? "lang-service-booking-checkout",
    path: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta?.props ?? false,
    meta: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta || {},
    alias: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta?.alias || [],
    redirect: checkout4TrknKD7frjDiLCBhucOZ11VmKFOQDMbaDDP5px8agoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta?.props ?? false,
    meta: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta || {},
    alias: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta?.alias || [],
    redirect: ko9WN5qRkgTaXxcOhcjqORhHhSpjRh893cgC1FGJs_457hoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta?.props ?? false,
    meta: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta || {},
    alias: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta?.alias || [],
    redirect: confirmation_haIuJIJynp58CXhOj5tJ6b_M_cahlBpH38io4nLrVEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta?.props ?? false,
    meta: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta || {},
    alias: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta?.alias || [],
    redirect: confirmationyav_9lcsO7y_YkyvYc_45m6c2Hkw7DL267BOPrInUJZe4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta?.props ?? false,
    meta: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta || {},
    alias: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta?.alias || [],
    redirect: buyerH_45agEEaj9OSLFPcTp3V3FXwRSUCe2HcFyIfKDiwndvUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta?.props ?? false,
    meta: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta || {},
    alias: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta?.alias || [],
    redirect: buyerFkRokYuE1BREcLX9CR781qA5hM4pZZYYdGDsQA6X3PsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta?.props ?? false,
    meta: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta || {},
    alias: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta?.alias || [],
    redirect: paymentYCnZE03w2_45wN2DO1kdGRHRRkqJp9HZIzi50NJmHoUaUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta?.props ?? false,
    meta: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta || {},
    alias: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta?.alias || [],
    redirect: challengebJunVYjee4enTAMPtztfG_45Xa4cjVvI5XgwJEj9T6ntQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta?.props ?? false,
    meta: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta || {},
    alias: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta?.alias || [],
    redirect: paymentmJ79RS5LP4jvgK6V7wgLcKsYrfIgP4Ns2PmcQ92xm0sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 0",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 1",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 2",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/alicante/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 3",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/altea/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 4",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/benidorm/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 5",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/calpe/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 6",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/denia/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 7",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/l-alfas-del-pi/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 8",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/la-nucia/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 9",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/mutxamel/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 10",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/rojales/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 11",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/alicante/teulada/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 12",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/comunidad-valenciana/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 13",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/sevilla/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 14",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/sevilla/sevilla/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 15",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/sevilla/umbrete/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 16",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/valencia/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 17",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/valencia/gandia/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 18",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/valencia/paterna/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.name ?? "Spa by location landing: 19",
    path: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.path ?? "/spa-balneario/valencia/valencia/",
    props: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.props ?? false,
    meta: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta || {},
    alias: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.alias || [],
    redirect: SpaList_46page1IUaNl_45BZndfOAhvYFFE7ag5luxVeuuKj88f4gk6u_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/luxor-spa/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/",
    props: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.props ?? false,
    meta: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta || {},
    alias: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.alias || [],
    redirect: SpaDetail_46pageqsQHZjcsj4dldIA1MYGfswyHc6ltPNcCAtIO9yH4DMYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-podal-oriental/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/terapia-de-perla-y-oro/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/reina-de-egipto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-samay-30min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/spa-privado-vip-masaje-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-prenatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-espalda/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/capuccino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-equilibrante-shiatsu/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-tailandes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-cuatro-manos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/luz-del-mediterraneo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/exotico-ceilan/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/piedras-de-yucamani/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/hidromasaje-de-esencias/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/etna-circuito-masaje-aromaterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/masaje-velas-calientes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/circuito-kumaras-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/chocolaterapia-termoactiva/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/nirvana-circuito-privado-masaje-velas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/kumaras-sensations-privado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/paani-plus-circuito-masaje-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/masaje-piedras-volcanicas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/masaje-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/vinoterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-kumaras/masaje-champi/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/prenatal-y-posnatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/esencias-y-sentidos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-nino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/chocolate/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/barro/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-podal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-corporal-ayurveda/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/hydraluronic/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-hawaiano/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-local-o-craneo-facial/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-mousse-de-chocolate/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/marine-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/albir-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-detox/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-aromaterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/vip-senses/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-relajante-general/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-ninos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-martes-a-viernes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-martes-a-viernes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-geotermal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-tango/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-sensorial-de-cabeza-y-cuero-cabelludo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-oriental-spa-1-pax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-naranja-y-acceso-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/relajese-disfrute/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-tango-y-acceso-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-hawaiano-lomi-lomi/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-balines-envolvente/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/relajese-y-disfrute-2-pax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/momento-spa-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-de-oriente-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-naranja-y-acceso-al-circuito-spa-1persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/spa-para-dos-2-pax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/limpieza-profunda-con-hidromass/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/higiene-facial-expres/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-chocolaterapia-60-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-lirio/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-jade/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-relajante-30-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/exfoliacion-corporal-hidratacion/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-orquidea/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-descontracturante-30-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-loto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-pindas-con-extracto-de-cerveza-completo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-relax-con-vela-aromatica-de-75-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-beauty-tratamiento-facial-55/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-con-vela-circuito-spa-90min-masaje-relajante-con-vela-aromatica-de-75min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-serenity-circuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-buenos-dias-desayuno-buffet-circuito-spa-90min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-gastro-relax-comida-cena-agua-y-vino-de-la-casa-incl-circuito-spa90min-buffet-o-menu-segun-ocupacion/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-renovadora-circuito-spa-de-90min-limpieza-facial-basica-masaje-orient-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-supreme-minimo-2-personas-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-supreme-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-envolvente-circuito-spa-90min-ritual-corporal-con-exfoliacion-y-envolturalimpieza-facial-basica/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-serenitycircuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-min-2-personas-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-25-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-spa-de-90min-a-partir-de-15-anos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-holistico-de-piedras-25-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-individual/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/tratamiento-facial-antioxidante-de-vitamina-c/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-individual/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/chocolaterapia-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje-y-cena/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet-con-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-cena/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-cena-buffet/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/circuito-spa-clientes-externos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-jaccuzi-individual/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-radiance-c/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/bono-4-sesiones-circuito-spa-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/velada-romantica/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/bono-10-sesiones-circuito-spa-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-adulto-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-hydraluronic/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-pureza-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-relajante-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/experiencia-sun-repair/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-menores-4-17-anos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/deep-tissue-masaje-localizado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-bienestar/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/viaje-a-la-provenza/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/viaje-al-eden-tropical/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-nino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-nino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-cocktail-en-skybar-adulto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/hidroterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-mahana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-local-relajante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-hindu/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-50min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-relajante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa-para-2-adultos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/circuito-termal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-sueco/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/ritual-evasion-de-los-sentidos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-reconfortante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-marruecos-1h-45/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-masaje-aromatico-50-y-piscina-spa-2h/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-aromatico-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-bangalore-80-y-piscina-spa-2h/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-marruecos-105-y-piscina-spa-2h/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-bangalore-1h-20/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-java-45-y-piscina-spa-2h/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-java-45/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-domingos-de-piscina-spa-y-brunch/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-agua-y-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-basic/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-premium/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-2h-acceso/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/especial-parejas-2h-piscina-spa-masaje-aromatico-25/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-futura-mama-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-ayurvedico-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/masaje-en-pareja-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-y-gastronomia-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-y-desayuno/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-para-parejas-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/pack-wellness-spa-y-masaje-para-dos-2-horas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/friends-experience/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-relajante-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante-momento-en-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-facial-express/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-facial-con-rodillo-de-jade/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-descontracturante-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-2-adultos-2-ninos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/relajese-y-disfrute-viernes-a-domingo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/exfoliacion-aromatica-energizante-con-especias-raras-y-preciosas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/exfoliacion-purificante-con-jabon-negro-beldi/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamiento-precioso-ko-bi-do-anti-edad-global/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-de-aromaterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/day-pass-de-viernes-a-domingo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/day-pass-lunes-a-jueves/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/relajese-y-disfrute-de-lunes-a-jueves/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-relajante-oriental-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-balines-envolvente/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-deportivo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-ayurvedico-de-la-india-tonificante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamiento-regenerante-ritual-de-flores-y-frutas-de-bali/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-relajante-oriental-30/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-de-espalda/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamiento-alisante-y-reafirmante-ko-bi-do/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-sensorial-de-cabeza/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-thai-oil/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-facial-de-oriente/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/drenaje-linfatico/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/spa-privado-para-dos-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-deportivo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/pack-spa-experience-para-dos-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-relajante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-prenatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-geo-serawa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-hawaiano/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/circuito-spa-1-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/circuito-spa-2-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-gastronomia-para-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-masaje-y-gastronomia-para-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-masaje-para-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-nino-90/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hawaiian-lomi-lomi-parejas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/masaje-infantil/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/abhyanga/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/ritual-de-la-vela-parejas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-evasion-spa-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-brunch-para-2-personas-spa-ritual-brunch/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/belleza-esencial-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/piernas-cansadas-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-spa-privado-para-dos-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-relax-spa-masaje-gastronomia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/masaje-para-embarazadas-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-spa-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/alegria-de-azahar-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/masaje-profundo-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/purificacion-ciudad-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/aromaterapia-relax-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-para-2-personas-spa-ritual/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/juventud-eterna-para-una-persona/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-50-min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/circuito-spa-senda-de-los-sentidos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/limpieza-profunda-tratamiento-contorno-de-ojos-is-clinical/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/despertar-laguna/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suite-royal-therapy/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suenos-mediterraneos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-25min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-localizado-20/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/bain-de-luxe/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/deep-tissue-massage-localizado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-skin-care-deluxe/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/craneo-facial-hindu/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-skin-care-premium/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/rituel-du-sensei/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/deep-tissue-completo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/privilege-mi-momento-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/reina-de-egipto-massage/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-luxury-1-adulto-1-nino-spa-y-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-wellness-weekweekend/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-spa-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/esencia-prenatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-a-la-prosperidad-y-el-exito/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/prosperidad-y-exito-massage/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/sueno-de-ambar-massage-by-morjana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-privado-luxury-en-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-man-massage-yang-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-woman-massage-yin-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-oasis-de-sabor-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-jardin-de-los-deseos-en-pareja-ying-yang-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-sueno-de-oud-by-morjana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/tranquility-body-face-by-comfortzone/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-privado-viaje-de-oriente-aroma-a-oud-ambar-by-morjana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-woman-ritual-yin-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-sueno-de-ambar-by-morjana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/tranquility-massage-by-comfortzone/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/essential-hot-oils-massage-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/sueno-de-oud-massage-by-morjana/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-a-4-manos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/night-private-spa-spa-privado-nocturno/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/chocomassage-en-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/ayurveda-shiro-abhyanga/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/calm-free-massage/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-privado-luxury-90min/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/drenaje-linfatico/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-calm-wellness-en-pareja-con-cava-y-bombones/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-momento-gourmet/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/oasis-de-la-serenidad-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-essentially-beautiful-by-alqvimia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-m-v/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/gastro-wellness-experience-cena-spa-mascarilla/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wine-wellness-para-dos-especial-navidad/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-christmas-day-pass-especial-navidad/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-en-pareja/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-brunch-experience-desayuno-buffet-spa-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/day-pass-masaje-cocktail/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-relajante/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-30/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-l-v/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-s-d/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-s-d/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-m-v/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-s-d/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-best-sellers-christmas-edition-especial-navidad/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/minerva-circuito-masaje-holistico/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/marenostrum-circuito-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/triton-circuito-flotarium/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/ritual-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/flotarium-30/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/flotarium-15/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/circuito-hispalis/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/ritual-al-andalus/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/eros-circuito-privado-ritual-al-andalus/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/vinoterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/chocolaterapia-termoactiva/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/neptuno-circuito-flotarium-masaje/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/hispalis-sensations-circuito-privado/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/masaje-piedras-volcanicas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/sevilla/termas-de-hispalis/masaje-relax/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-belleza-oriental-duo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen-duo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience-duo/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-embarazadas-nuad-kon-thong-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-de-bambu-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/envoltura-natural-para-una-piel-perfecta-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-con-aceite-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-4-manos-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pareja-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-wellness/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-pareja-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-zen-parejas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-y-masaje-aromaterapia/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas-1/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-masaje-y-banera-privada-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-para-2/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal-masaje-20-pack-romantico-para-2-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-spa-90-masaje-20-para-2-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-spa-90-masaje-40-para-2-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal-para-2-personas/",
    props: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.props ?? false,
    meta: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta || {},
    alias: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.alias || [],
    redirect: ServiceDetail_46pagegEJRjLlXht_38EIyI1FHoNh14_z_JRGUc1ikj2OKsQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-5ab4c8ac-aa5f-4d6f-8325-b558c36f1db4/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]